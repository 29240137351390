import { slugify, unorphan, urlFor } from '@global-av-survey/lib/helpers';

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Layout } from '../containers';
import { OffsiteLink } from '../components';

import jobsAndEconomyUrl from '../artwork/scenarios/jobs-and-economy.jpg';
import jobsAndEconomy2xUrl from '../artwork/scenarios/jobs-and-economy@2x.jpg';
import mobilityUrl from '../artwork/scenarios/mobility.jpg';
import mobility2xUrl from '../artwork/scenarios/mobility@2x.jpg';
import sustainabilityUrl from '../artwork/scenarios/sustainability.jpg';
import sustainability2xUrl from '../artwork/scenarios/sustainability@2x.jpg';
import urbanTransformationUrl from '../artwork/scenarios/urban-transformation.jpg';
import urbanTransformation2xUrl from '../artwork/scenarios/urban-transformation@2x.jpg';

function mapStateToProps (state) {
  return {
    scenarios: Object.keys(state.content.scenarios).map(title => ({
      title,
      ...state.content.scenarios[title],
    }))
  };
}

const artwork = {
  'jobs-and-the-economy': {
    src: jobsAndEconomyUrl,
    srcSet: `${jobsAndEconomyUrl} 1x, ${jobsAndEconomy2xUrl} 2x`,
    width: 600,
    height: 400
  },
  'mobility': {
    src: mobilityUrl,
    srcSet: `${mobilityUrl} 1x, ${mobility2xUrl} 2x`,
    width: 600,
    height: 400
  },
  'sustainability': {
    src: sustainabilityUrl,
    srcSet: `${sustainabilityUrl} 1x, ${sustainability2xUrl} 2x`,
    width: 600,
    height: 400
  },
  'urban-transformation': {
    src: urbanTransformationUrl,
    srcSet: `${urbanTransformationUrl} 1x, ${urbanTransformation2xUrl} 2x`,
    width: 600,
    height: 400
  }
};

function Scenario ({ scenario }) {
  const props = {
    to: urlFor('scenario', scenario),
    className: 'Scenarios__item',
    style: {
      backgroundColor: scenario.color
    }
  };

  const slug = slugify(scenario.subtitle);

  return (
    <Link {...props}>
      <h3 className="Scenarios__item-subtitle">{scenario.subtitle}:</h3>
      <h2 className="Scenarios__item-title">{scenario.title}</h2>
      <img
        {...artwork[slug]}
        alt={`${scenario.subtitle}: ${scenario.title}`}
      />
    </Link>
  );
}

class Scenarios extends React.PureComponent {
  componentDidMount () {
    unorphan('p');
  }

  componentDidUpdate () {
    unorphan('p');
  }


  render () {
    const { scenarios } = this.props;

    return (
      <Layout name="Scenarios">
        <main className="Layout__main">
          <div className="Scenarios">
            <div className="Scenarios__intro">
              <p className="lead">
                The full story of autonomous vehicles is yet to be written. We created four stories that explain how cities could shape the driverless future.
              </p>

              <div className="Scenarios__grid">
                {scenarios.map((scenario, index) => <Scenario key={scenario.subtitle} scenario={scenario} />)}
              </div>

              <p className="lead">
                The unstoppable forces of automation and artificial intelligence are changing the way we move through, work in, and design cities.
              </p>

              <div className="columns">
                <div>
                  <p>
                  Autonomous vehicles (AVs) are already on our streets, with pilots taking place in cities nationwide. Before long, we can expect to see thousands of autonomous vehicles on roadways, autonomous buses and transit vehicles providing rides, and autonomous conveyors shuttling back and forth on sidewalks making deliveries.
                  </p>
                  <p>
                  The full story, however has not yet been written. While we will inevitably see rapid expansion of autonomous transportation in commercial trucks, driverless buses, trains, shuttles, and more—transportation systems as a whole will also be revolutionized. Yet this change comes at a time when our shared networks – vital arteries for commerce and interaction - are already clogged.
                  </p>
                  <p>
                  So while technology has the potential to address the challenges facing these platforms for commerce and human interaction, effective government will be critical in pushing innovation forward. That future is already starting to unfold, but cities can prepare themselves to play a more informed, active role in shaping it.
                  </p>
                </div>
                <div>
                  <p>
                  This is why at the National League of Cities we have developed—and continue to work on—a series of <OffsiteLink to="https://www.nlc.org/cityofthefuture">research reports and analyses</OffsiteLink> to help city leaders prepare for these shifts.
                  </p>
                  <p>
                  Explore the links below to see four possible futures that describe what AVs could mean for cities. The scenarios, developed by the Bloomberg Aspen Initiative on Cities and Autonomous Vehicles, and reported by journalist Greg Lindsay, are part and parcel of NLC’s larger initiative to provide city leaders with the tools that they need to build our cities of the future.
                  </p>
                  <p>
                  For cities that want to get up to speed quickly on key facts, emerging trends, and urban policy issues raised by the arrival of autonomous vehicles and found out more about how cities are taking a hands-on approach to learning, Bloomberg Philanthropies has published a <OffsiteLink to="https://www.bbhub.io/dotorg/sites/2/2017/05/TamingtheAutonomousVehicleSpreadsPDF.pdf">primer</OffsiteLink> and a <OffsiteLink to="https://avsincities.bloomberg.org">global atlas</OffsiteLink> of city-led AV pilots covering more than 100 city-led AV pilots worldwide.
                  </p>
                 </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    );
  }
}

export default connect(mapStateToProps)(Scenarios);
