export default function scaleCoordinate(_ref) {
  var _ref$x = _ref.x,
      x = _ref$x === void 0 ? 0 : _ref$x,
      _ref$y = _ref.y,
      y = _ref$y === void 0 ? 0 : _ref$y,
      _ref$oldMin = _ref.oldMin,
      oldMin = _ref$oldMin === void 0 ? [0, 0] : _ref$oldMin,
      _ref$oldMax = _ref.oldMax,
      oldMax = _ref$oldMax === void 0 ? [0, 0] : _ref$oldMax,
      _ref$newMin = _ref.newMin,
      newMin = _ref$newMin === void 0 ? [0, 0] : _ref$newMin,
      _ref$newMax = _ref.newMax,
      newMax = _ref$newMax === void 0 ? [0, 0] : _ref$newMax;
  return {
    x: (newMax[0] - newMin[0]) * (x - oldMin[0]) / (oldMax[0] - oldMin[0]) + newMin[0],
    y: (newMax[1] - newMin[1]) * (y - oldMin[1]) / (oldMax[1] - oldMin[1]) + newMin[1]
  };
}