import { get } from 'lodash';
import getProps from '@global-av-survey/lib/helpers/getProps';

import React from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Footer, Nav } from '../components';

import { SITE_HOST } from '../consts';

/**
 * Layout
 */

function mapStateToProps (state) {
  return {
    isNavOpen: get(state, 'content.isNavOpen'),
    pathname: get(state, 'router.location.pathname'),
    sections: get(state, 'content.sections'),
    site: get(state, 'content.site'),
  };
}

class Layout extends React.PureComponent {
  render () {
    const {
      category,
      children,
      isNavOpen,
      layoutRef,
      name,
      navRef,
      ogType = 'website',
      pathname,
      site
    } = this.props;

    let {
      title = site.title,
      description = site.description,
      image = site.image
    } = this.props;

    const isRoot = pathname === '/';

    const htmlProps = getProps({
      name: 'Layout',
      props: {
        category,
        isNavOpen,
        isNavSticky: true
      }
    });

    if (!isRoot) title = `${title} / ${site.title}`;

    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="twitter:site" content="@leagueofcities" />
          <meta name="twitter:title" content={title} />
          <meta property="og:title" content={title} />
          <meta property="og:url" content={`${SITE_HOST}${pathname}`} />
          {ogType && <meta property="og:type" content={ogType} />}
        </Helmet>
        {description && (
          <Helmet>
            <meta name="description" content={description} />
            <meta name="twitter:description" content={description} />
            <meta property="og:description" content={description} />
          </Helmet>
        )}
        {image && (
          <Helmet>
            <meta name="twitter:image" content={`${SITE_HOST}${image.path}`} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="og:image" content={`${SITE_HOST}${image.path}`} />
            <meta property="og:image:width" content={image.width} />
            <meta property="og:image:height" content={image.height} />
          </Helmet>
        )}
        <div data-route={pathname} data-page={name} {...htmlProps} ref={layoutRef}>
          <Nav className="Layout__nav" navRef={navRef} pathname={pathname} />
          {children}
          <Footer className="Layout__footer" />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Layout);
