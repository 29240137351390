import slugify from './slugify';

function getPath(name, params) {
  switch (name) {
    case 'city':
      return ['global-atlas', params.region, params.countryCode, params.id];

    case 'country':
      return ['global-atlas', params.region, params.countryCode];

    case 'region':
      return ['global-atlas', params.region];

    case 'scenario':
      return [slugify(params.subtitle)];

    default:
      return [''];
  }
}

export default function urlFor(name, params) {
  var path = getPath(name, params);
  return '/' + path.map(slugify).join('/');
}