import getProps from '@global-av-survey/lib/helpers/getProps';

import React from 'react';
import { OffsiteLink } from '../components';

/**
 * Footer
 */

export default function Footer ({ className }) {
  const htmlProps = getProps({ name: 'Footer', props: { className } });

  return (
    <footer {...htmlProps}>
      <div className="Footer__content columns">
        <div>
          <p>
            Presented by the National League of Cities Center for City Solutions
          </p>
          <OffsiteLink className="Footer__logo" to="https://www.nlc.org">
            <img width="197" height="72" src="/images/logos/nlc.svg" alt="NLC logo" />
          </OffsiteLink>
        </div>
        <div>
          <p>
            Supported by Bloomberg Philanthropies as part of the Bloomberg Aspen Initiative on Cities and Autonomous Vehicles
          </p>

          <OffsiteLink className="Footer__logo" to="https://www.bloomberg.org">
            <img width="177" height="72" src="/images/logos/bloomberg-philanthropies.svg" alt="Bloomberg Philanthropies logo" />
          </OffsiteLink>
          <OffsiteLink className="Footer__logo" to="https://www.aspeninstitute.org/programs/center-urban-innovation/">
            <img width="226" height="72" src="/images/logos/aspen-institute.svg" alt="The Aspen Institute logo" />
          </OffsiteLink>
        </div>
        <p className="Footer__legal"><OffsiteLink to="https://www.nlc.org/privacy-policy">Privacy Policy</OffsiteLink></p>
      </div>
    </footer>
  );
}
