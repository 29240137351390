import React from 'react';
import Artwork from './Artwork';
import getProps from '@global-av-survey/lib/helpers/getProps';

export default function CallToAction (props) {
  const { className, artwork } = props;

  const htmlProps = getProps({ name: 'CallToAction', props: { artwork, className } });

  return (
    <div {...htmlProps}>
      <div className="CallToAction__artwork">
        <Artwork name={artwork} width={50} height={50} />
      </div>
      {props.children}
    </div>
  );
}
