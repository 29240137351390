import './bundle.css';
import svgBundleUrl from './bundle.svg';
import init from '@global-av-survey/lib/init';

import React from 'react';
import { Route } from 'react-router-dom';
import { NotFound, Scenario, Scenarios, AboutScenarios } from './containers';

import content from './content.json';

const routes = [
  <Route exact path="/" component={Scenarios} />,
  <Route exact path="/about" component={AboutScenarios} />,
  <Route exact path="/:slug" component={Scenario} />,
  <Route component={NotFound} />
];

init({
  routes,
  svgBundleUrl,
  content: { ...content }
});
