import { pick, pickBy } from 'lodash';
import bemClassString from './bemClassString';

function getProps(_ref) {
  var name = _ref.name,
      props = _ref.props,
      state = _ref.state;
  var htmlProps = pick(props, ['id', 'className', 'style']);
  var styleProps = pick(props, ['artwork', 'category', 'className', 'name']);
  htmlProps.className = bemClassString({
    name: name,
    state: state,
    props: Object.assign({}, styleProps, state, pickBy(props, function (value) {
      return typeof value === 'boolean';
    }))
  });
  return htmlProps;
}

export default getProps;