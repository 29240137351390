import React from 'react';
import { Link } from 'react-router-dom';
import { Intro } from '../components';

export default function NotFound () {
  return (
    <div className="NotFound">
      <Intro>
        <h2>
          Our autonomously navigating robot brains were not able to locate the resource you requested, but perhaps you would be interested in some <Link to="/">evolving stories from the future</Link> or urban mobility?
        </h2>
      </Intro>
    </div>
  );
}
