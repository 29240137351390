export var NARROW = 500;
export var MID = 1090;
export var STANDARD = 1460;
export var minNarrow = window.matchMedia("(min-width: ".concat(NARROW + 1, "px)"));
export var minMid = window.matchMedia("(min-width: ".concat(MID + 1, "px)"));
export var minStandard = window.matchMedia("(min-width: ".concat(STANDARD + 1, "px)"));
export var maxStandard = window.matchMedia("(max-width: ".concat(STANDARD, "px)"));
export var maxMid = window.matchMedia("(max-width: ".concat(MID, "px)"));
export var maxNarrow = window.matchMedia("(max-width: ".concat(NARROW, "px)"));
export var print = window.matchMedia('print');
export var landscape = window.matchMedia('(orientation: landscape)');