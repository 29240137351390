import { debounce, get } from 'lodash';
import { bemClassString, getProps, unorphan, urlFor } from '@global-av-survey/lib/helpers';

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import hoverIntent from 'hoverintent';

import Artwork from './Artwork';
import * as actions from '@global-av-survey/lib/state/actions';
import { maxMid } from '@global-av-survey/lib/styles/breakpoints';
import { BLACK, WHITE } from '@global-av-survey/lib/styles/colors';

/**
 * Nav
 */

function SectionMenu ({ isOpen, isMobile, scenarios, section }) {
  let links = [{
    text: 'Explore',
    url: '#',
    backgroundColor: BLACK,
    color: WHITE,
    hideMobile: isMobile
  }].concat(Object.keys(scenarios).map(title => ({
    text: title,
    url: urlFor('scenario', scenarios[title]),
    backgroundColor: scenarios[title].color
  })), {
    text: 'About',
    url: '/about',
    backgroundColor: BLACK,
    color: WHITE,
  },{
    text: 'Home',
    url: '/',
    backgroundColor: BLACK,
    color: WHITE,
  });

  if (links && links.length) links[0].isTitle = true;

  return (
    <div className="Nav__section-menu">
      {links.filter(link => !link.hideMobile).map(link => (
        <Link
          key={link.text}
          to={link.url}
          children={link.text}
          className={bemClassString({ name: 'Nav__section-menu-link', props: { isTitle: link.isTitle }})}
          style={{
            backgroundColor: link.backgroundColor,
            color: link.color || BLACK,
            display: !link.isTitle && !isOpen ? 'none' : 'block'
          }}
        />
      ))}
    </div>
  );
}

function Toggle ({ closeNav, openNav, isMobile, isOpen }) {
  if (isOpen && isMobile) return <Artwork className="Nav__toggle" name="nav-close" onClick={closeNav} />;
  if (isMobile) return <Artwork className="Nav__toggle" name="nav-thick" onClick={openNav} />;
  return <Artwork className="Nav__toggle" name="nav" />;
}

function mapStateToProps (state) {
  return {
    colors: get(state, 'content.colors'),
    scenarios: get(state, 'content.scenarios'),
    isNavOpen: get(state, 'content.isNavOpen')
  };
}

function mapDispatchToProps (dispatch) {
  return {
    closeNav: () => dispatch(actions.closeNav()),
    openNav: (event) => {
      if (event === 'hover') return dispatch(actions.openNav());

      // whitelist open triggers to avoid close/open conflict
      const allowed = [
        'Nav__content',
        'Nav__section-menu-link--title',
        'Nav__toggle'
      ];

      const classList = event.target.classList;

      if (
        event.target.classList &&
        allowed.find(className => classList.contains(className)) &&
        !classList.contains('Artwork--nav-close')
      ) {
        dispatch(actions.openNav());
      }
    }
  };
}

class Nav extends React.PureComponent {
  constructor (props) {
    super(props);

    this.handleClickOutside = props.closeNav;
    this.handleHover = debounce(props.openNav, 500);
    this.handleResize = this.handleResize.bind(this);

    this.state = {};
  }

  handleResize () {
    if (maxMid.matches === this.state.isMobile) return;
    const isMobile = maxMid.matches;

    this.setState({ isMobile });

    if (isMobile) {
      this.hoverListener && this.hoverListener.remove();
    } else {
      this.hoverListener = hoverIntent(this.el, this.props.openNav.bind(null, 'hover'), this.props.closeNav);
    }
  }

  componentDidMount () {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    unorphan('.Nav a');
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize);
  }

  render () {
    const {
      className,
      closeNav,
      colors,
      isNavOpen,
      navRef,
      openNav,
      scenarios,
    } = this.props;

    const {
      isMobile
    } = this.state;

    const htmlProps = getProps({
      name: 'Nav',
      props: {
        className,
        isOpen: isNavOpen
      }
    });

    return (
      <nav {...htmlProps} ref={(el) => { this.el = el; navRef && navRef(el) }} onMouseLeave={closeNav}>
        <div className="Nav__content">
          <h1 className="Nav__home">
            <Link className="Nav__home-link" to="/">Autonomous Vehicles: Future Scenarios</Link>
          </h1>
          <SectionMenu
            isOpen={isNavOpen}
            isMobile={isMobile}
            colors={colors}
            scenarios={scenarios}
          />
          <Toggle closeNav={closeNav} openNav={openNav} isMobile={isMobile} isOpen={isNavOpen} />
        </div>
      </nav>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  onClickOutside(Nav)
);
