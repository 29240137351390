import bemClassNames from 'bem-classnames';
import { kebabCase } from 'lodash';
var MODIFIERS = ['artwork', 'category', 'name'];

function bemClassString(_ref) {
  var name = _ref.name,
      _ref$props = _ref.props,
      props = _ref$props === void 0 ? {} : _ref$props;
  // custom classes
  var className = props.className && ' ' + props.className || '';
  var styleProps = Object.keys(props).reduce(function (hash, key) {
    // normalize boolean keys
    var newKey = kebabCase(key.replace(/^is/, '')); // normalize string/number values

    if (typeof props[key] === 'string') {
      hash[newKey] = kebabCase(props[key]);
    } else if (typeof props[key] === 'number') {
      hash[newKey] = props[key].toString() + 'x';
    } else {
      hash[newKey] = props[key];
    }

    return hash;
  }, {});
  var config = {
    name: name,
    modifiers: [].concat(Object.keys(styleProps).filter(function (key) {
      return typeof styleProps[key] === 'boolean';
    })).concat(Object.keys(styleProps).filter(function (key) {
      return MODIFIERS.includes(key);
    }))
  };
  return (bemClassNames(config, styleProps) + className).replace(/-x/g, 'x');
}

export default bemClassString;