import _objectSpread from "/home/circleci/global-av-survey/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import 'scrollingelement'; // polyfill document.scrollingElement

import browser from 'bowser'; // IE 11 polyfills

import 'core-js/fn/array/find';
import 'core-js/fn/array/includes';
import 'core-js/fn/string/includes';
import 'core-js/fn/string/repeat';
import 'core-js/fn/math/sign';
import 'core-js/fn/math/trunc';
import 'core-js/fn/number/is-nan';
import 'core-js/fn/object/assign';
import 'core-js/fn/object/values';
import 'core-js/fn/symbol';
import 'core-js/fn/typed/uint8-clamped-array';
import 'dom4'; // for SVG classList
// helpers

import { generate as UID } from 'shortid'; // logging

import { default as log, logRoutes } from './helpers/logger';
import Raven from 'raven-js';
import createRavenMiddleware from 'raven-for-redux'; // react

import React from 'react';
import ReactDOM from 'react-dom';
import { Switch } from 'react-router-dom';
import { ConnectedRouter, routerReducer, routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import { Provider } from 'react-redux';
import * as actions from './state/actions';
import reducers from './state/reducers';
import uiReducers from './state/reducers.ui';
export default function init(_ref) {
  var content = _ref.content,
      routes = _ref.routes,
      svgBundleUrl = _ref.svgBundleUrl;
  if (browser.msie && browser.version <= 11) window.location = 'http://outdatedbrowser.com'; // routing

  var history = createBrowserHistory();
  history.listen(function () {
    if (!window.tetherTooltips) return;
    window.tetherTooltips.forEach(function (t) {
      return t.close();
    });
  });
  var ROUTES = routes.map(function (route) {
    return _objectSpread({}, route, {
      key: UID()
    });
  });
  if (process.env.NODE_ENV !== 'production') logRoutes(ROUTES); // redux

  var enhancer = compose(applyMiddleware(routerMiddleware(history)), applyMiddleware(createRavenMiddleware(Raven)), window.devToolsExtension ? window.devToolsExtension() : function (f) {
    return f;
  });
  var store = createStore(combineReducers({
    content: reducers,
    router: routerReducer,
    ui: uiReducers
  }), {}, enhancer); // logging

  window.log = log;
  if (process.env.NODE_ENV !== 'production') log.setLevel('debug');
  var SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

  if (SENTRY_DSN) {
    Raven.config(SENTRY_DSN).install();
    window.Raven = Raven;
    if (/testException/.test(window.location.search)) test.reduce(function () {
      return true;
    });
  } // fetch svg sprite and mount


  store.dispatch(actions.getContent(content));
  window.fetch(svgBundleUrl).then(function (res) {
    return res.text();
  }).then(function (svgData) {
    document.querySelector('#svg').insertAdjacentHTML('beforeend', svgData);
    mount({
      store: store,
      history: history,
      routes: ROUTES
    });
  }).catch(log.error);
}

function mount(_ref2) {
  var store = _ref2.store,
      history = _ref2.history,
      routes = _ref2.routes;
  var rootEl = document.getElementById('root');
  var tree = React.createElement(Provider, {
    store: store
  }, React.createElement(ConnectedRouter, {
    history: history
  }, React.createElement(Switch, {}, routes)));
  if (rootEl.firstChild) rootEl.removeChild(rootEl.firstChild);
  rootEl.hasChildNodes() ? ReactDOM.hydrate(tree, rootEl) : ReactDOM.render(tree, rootEl);
}