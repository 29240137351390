import getProps from '@global-av-survey/lib/helpers/getProps';

import React from 'react';

/**
 * Intro
 */

export default function Intro ({ className, children }) {
  const htmlProps = getProps({ name: 'Intro', props: { className } });

  return (
    <header {...htmlProps}>
      {children}
    </header>
  );
}
