import getProps from '@global-av-survey/lib/helpers/getProps';

import React from 'react';

function Artwork ({ name, className, width, height, onClick, style }) {
  const htmlProps = getProps({ name: 'Artwork', props: { className, name } });

  let symbol = {
    __html: `<use xlink:href=#${name}></use>`
  };

  return (
    <svg
      {...htmlProps}
      dangerouslySetInnerHTML={symbol}
      width={width}
      height={height}
      style={style}
      onClick={onClick}
    />
  );
}

export default Artwork;
