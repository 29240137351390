import { get } from 'lodash';
import { bem, slugify, urlFor, unorphan } from '@global-av-survey/lib/helpers';

import React from 'react';
import { connect } from 'react-redux';
import { Layout, NotFound } from '../containers';
import { CallToAction, FooterNav, Slideshow } from '../components';

import {
  JobsAndEconomy,
  Mobility,
  Sustainability,
  UrbanTransformation,
} from '../artwork/scenarios';

import remark from 'remark';
import reactRenderer from 'remark-react';
import align from 'remark-align';
import zone from 'mdast-zone';

const SECTIONS = [
  'download',
  'intro',
  'slideshow'
];

function sections () {
  return tree => SECTIONS.forEach(name => zone(tree, name, mutate.bind(null, name)));

  function mutate (name, start, nodes, end) {
    return [
      start,
      {
        type: 'element',
        data: {
          hName: 'section',
          hProperties: {
            className: `Scenario__${name}`
          }
        },
        children: nodes,
      },
      end
    ];
  }
}

function unwrapImages () {
  return tree => {
    tree.children = tree.children.map(token => {
      if (token.type !== 'paragraph') return token;
      if (!token.children || token.children[0].type !== 'image') return token;
      return token.children[0];
    });

    return tree;
  };
}

function Image ({ alt, src }) {
  const classString = bem({
    name: 'Scenario__image',
    props: {
      illustration: src.includes('svg'),
      photo: !src.includes('svg')
    }
  });

  return (
    <figure className={classString}>
      <img className="Scenario__image-content" src={src} alt={alt} />
      <figcaption className="Scenario__image-caption">
        <p>{alt}</p>
      </figcaption>
    </figure>
  );
}

function mapStateToProps (state, ownProps) {
  const slug = ownProps.match.params.slug;
  const content = state.content.scenarios;
  const key = Object.keys(content).find(key => slugify(content[key].subtitle) === slug);

  return {
    pathname: get(state, 'router.location.pathname'),
    scenario: content[key],
    scenarios: get(state, 'content.scenarios'),
    title: key
  };
}

class Markdown extends React.PureComponent {
  render () {
    const { scenario, slug } = this.props;

    if (!scenario.text) return null;

    return remark()
      .use(sections)
      .use(unwrapImages)
      .use(align, {
        left: 'pull-left',
        right: 'pull-right'
      })
      .use(reactRenderer, {
        prefix: slug,
        sanitize: false,
        remarkReactComponents: {
          blockquote: ({ children }) => (
            <blockquote style={{ backgroundColor: scenario.color }}>
              {children}
            </blockquote>
          ),
          h1: ({ children }) => (
            <h1 style={{ backgroundColor: scenario.color }}>
              <span>{children}</span>
            </h1>
          ),
          h2: ({ children }) => (
            <h2>
              <span>{children}</span>
            </h2>
          ),
          h3: ({ children }) => (
            <h3>
              <span>{children}</span>
            </h3>
          ),
          img: Image,
          section: (props) => {
            switch (props.className) {
              case 'Scenario__download':
                return <CallToAction {...props} artwork="download" />;
              case 'Scenario__slideshow':
                return <Slideshow {...props} />;
              default:
                return <section {...props} />;
            }
          }
        },
      })
      .processSync(scenario.text).contents;
  }
}

function Poster ({ slug, visibility }) {
  let Component;
  if (slug === 'mobility') Component = Mobility;
  if (slug === 'jobs-and-the-economy') Component = JobsAndEconomy;
  if (slug === 'sustainability') Component = Sustainability;
  if (slug === 'urban-transformation') Component = UrbanTransformation;

  if (!Component) return null;
  return <Component className="Scenario__poster" visibility={visibility} />;
}

class Scenario extends React.PureComponent {
  componentDidMount () {
    unorphan('.Scenarios__body > div > p');
  }

  componentDidUpdate () {
    unorphan('.Scenarios__body > div > p');
  }

  render () {
    const { match, pathname, scenario, scenarios, title, visibility } = this.props;

    if (!scenario) return <NotFound />;

    const slug = match.params.slug;

    const links = Object.keys(scenarios)
      .filter(title => urlFor('scenario', scenarios[title]) !== pathname)
      .map(title => ({
        color: scenarios[title].color,
        text: title,
        url: urlFor('scenario', scenarios[title])
      }));

    return (
      <Layout name="Scenario" match={match} color={scenario.color} title={title} description={scenario.lead}>
        <main className="Layout__main Scenario">
          <h3 className="Scenario__subtitle">{scenario.subtitle}</h3>
          <Poster {...match.params} className="Scenario__poster" visibility={visibility} />
          <div className="Layout__overlay">
            <p className="Scenario__lead">{scenario.lead}</p>
            <div className="Scenario__body">
              <Markdown scenario={scenario} slug={slug} />
            </div>
            <FooterNav className="Scenario__nav" links={links} title="View Other Scenarios" />
          </div>
        </main>
      </Layout>
    );
  }
}

export default connect(mapStateToProps)(Scenario);
