import { CLOSE_NAV, GET_CONTENT, OPEN_NAV, SET_CITY, SET_INTERACTIVE_EL, SET_MAP, SET_NAV_OFFSET, SET_TAG, SET_WAYPOINT, SET_ZOOM } from './actionTypes';
/**
 * Close nav drawer
 *
 * @returns {Action} payload
 */

export function closeNav() {
  return {
    type: CLOSE_NAV
  };
}
/**
 * Get Content
 *
 * @returns {Action} payload
 */

export function getContent(data) {
  return {
    type: GET_CONTENT,
    payload: data
  };
}
/**
 * Open nav drawer
 *
 * @returns {Action} payload
 */

export function openNav() {
  return {
    type: OPEN_NAV
  };
}
/**
 * Set city
 *
 * @returns {Action} payload
 */

export function setCity(id) {
  return {
    type: SET_CITY,
    payload: id
  };
}
/**
 * Set interactive element
 *
 * @returns {Action} payload
 */

export function setInteractiveEl(id) {
  return {
    type: SET_INTERACTIVE_EL,
    payload: id
  };
}
/**
 * Set map
 *
 * @returns {Action} payload
 */

export function setMap() {
  return {
    type: SET_MAP
  };
}
/**
 * Set sticky navigation offset
 *
 * @returns {Action} payload
 */

export function setNavOffset(offset) {
  return {
    type: SET_NAV_OFFSET,
    payload: offset
  };
}
/**
 * Set tag
 *
 * @returns {Action} payload
 */

export function setTag(tag) {
  return {
    type: SET_TAG,
    payload: tag
  };
}
/**
 * Set waypoint position
 *
 * @returns {Action} payload
 */

export function setWaypoint(_ref) {
  var name = _ref.name,
      position = _ref.position;
  return {
    type: SET_WAYPOINT,
    payload: {
      name: name,
      position: position
    }
  };
}
/**
 * Set map zoom level
 *
 * @returns {Action} payload
 */

export function setZoom(level) {
  return {
    type: SET_ZOOM,
    payload: level
  };
}