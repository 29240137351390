import getProps from '@global-av-survey/lib/helpers/getProps';

import React from 'react';

export default function OffsiteLink ({ className, children, style, to }) {
  const htmlProps = getProps({ name: 'OffsiteLink', props: { className, style } });

  return (
    <a {...htmlProps} href={to} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}
