import { getProps, slugify } from '@global-av-survey/lib/helpers';

import React from 'react';
import { Link } from 'react-router-dom';
import OffsiteLink from './OffsiteLink';

/**
 * FooterNav
 */

export default function FooterNav (props) {
  const { className, links = [], title } = props;

  const htmlProps = getProps({ name: 'FooterNav', props: { className } });

  return (
    <nav {...htmlProps}>
      {title && <h1 className="FooterNav__heading"><span>{title}</span></h1>}
      {links.map(({ color, text, url }) => {
        if (!text || !url) return null;

        const Component = (url.substr(0, 4) === 'http')
          ? OffsiteLink
          : Link;

        return (
          <div key={text} className={`${slugify(text)}`}>
            <Component to={url} style={{ backgroundColor: color }}>
              {text}
            </Component>
          </div>
        );
      })}
    </nav>
  );
}
