import React from 'react';
import { InlineSVG, Placeholder } from '../../components';
import { TimelineMax, TweenMax } from 'gsap';

import svgUrl from './jobs-and-economy.svg';

const delay = 0.3;
const rotation = 0.7;

export default class JobsAndEconomy extends React.PureComponent {
  constructor () {
    super();
    this.state = {};
    this.animate = this.animate.bind(this);
  }

  componentDidMount () {
    window.fetch(svgUrl)
      .then(res => res.text())
      .then(data => {
        this.svgData = data;
        this.setState({ isLoaded: true });
      })
      .catch(window.log.error);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isLoaded !== this.state.isLoaded) this.animate();
  }

  componentWillReceiveProps (nextProps) {
    if (!this.state.isLoaded) return;

    if (nextProps.visibility === 'hidden') {
      this.roadway.pause();
      this.wheels.pause();
    } else {
      this.roadway.resume();
      this.wheels.resume();
    }
  }

  componentWillUnmount () {
    this.roadway.kill();
    this.wheels.kill();
  }

  animate () {
    const ROADWAY1 = document.getElementById('jobs-roadway-1');
    const ROADWAY2 = document.getElementById('jobs-roadway-2');
    const ROADWAY3 = document.getElementById('jobs-roadway-3');
    const LEFT_WHEEL = document.getElementById('jobs-wheel-left');
    const RIGHT_WHEEL = document.getElementById('jobs-wheel-right');

    this.roadway = new TimelineMax({ repeat: -1 });
    this.wheels = new TimelineMax({ repeat: -1, yoyo: true });

    TweenMax.set([
      ROADWAY1,
      ROADWAY2,
      ROADWAY3,
    ], { opacity: 0 });

    const SHOW = { opacity: 1 };
    const HIDE = { delay, opacity: 0 };

    this.roadway.add([
      TweenMax.to(ROADWAY1, 0, SHOW),
      TweenMax.to(ROADWAY1, 0, HIDE),
      TweenMax.to(ROADWAY2, 0, SHOW),
      TweenMax.to(ROADWAY2, 0, HIDE),
      TweenMax.to(ROADWAY3, 0, SHOW),
      TweenMax.to(ROADWAY3, 0, HIDE),
    ], '+=0', 'sequence', 0);

    this.wheels.add([
      TweenMax.to(LEFT_WHEEL, 0.2, { rotation }),
      TweenMax.to(RIGHT_WHEEL, 0.2, { rotation: rotation * -1 }),
    ], '+=0');

    this.roadway.play();
    this.wheels.play();
  }

  render () {
    if (!this.state.isLoaded) {
      return (
        <Placeholder
          width={1350}
          height={752}
        />
      );
    }

    return (
      <InlineSVG
        className={this.props.className}
        prefix="jobs"
        data={this.svgData}
        width={1350}
        height={752}
        alt="Buggy on a road"
      />
    );
  }
}
