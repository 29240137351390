import React from 'react';

export default function Placeholder ({ className, height, width, maxWidth }) {
  if (maxWidth && typeof maxWidth === 'boolean') maxWidth = `${width}px`;

  // https://css-tricks.com/scale-svg/
  const ratio = (height / width) * 100;

  const containerStyles = {
    maxWidth,
    position: 'relative',
    width: '100%',
    height: 0,
    padding: 0,
    paddingBottom: `${ratio}%`
  };

  const placeholderStyles = {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0
  };

  return (
    <div className={className} style={containerStyles}>
      <div
        className="Placeholder"
        children="Loading…"
        style={placeholderStyles}
      />
    </div>
  );
}
