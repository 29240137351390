import React from 'react';
import { connect } from 'react-redux';
import ReactWaypoint from 'react-waypoint';
import { setWaypoint } from '@global-av-survey/lib/state/actions';
import getProps from '@global-av-survey/lib/helpers/getProps';

function mapDispatchToProps (dispatch, ownProps) {
  return {
    setWaypoint: position => dispatch(setWaypoint({ position, name: ownProps.name })),
  };
}

function Waypoint (props) {
  const { children, setWaypoint, name, style } = props;
  const htmlProps = getProps({ name: 'Waypoint', props: { name, style } });

  return (
    <ReactWaypoint onPositionChange={setWaypoint}>
      <div {...htmlProps}>
        {children}
      </div>
    </ReactWaypoint>
  );
}

export default connect(null, mapDispatchToProps)(Waypoint);
