import React from 'react';

function processSVG (data, prefix) {
  return data
    .split(/\n/)
    .slice(2, -1)
    .join('')
    .replace(/id="/g, `id="${prefix}-`)
    .replace(/href="#/g, `href="#${prefix}-`)
    .replace(/url\(#/g, `url(#${prefix}-`)
}

export default function InlineSVG ({ className, data, height, width, maxWidth, svgRef, prefix, url }) {
  if (maxWidth && typeof maxWidth === 'boolean') maxWidth = `${width}px`;

  // https://css-tricks.com/scale-svg/
  const ratio = (height / width) * 100;

  const containerStyles = {
    maxWidth,
    position: 'relative',
    width: '100%',
    height: 0,
    padding: 0,
    paddingBottom: `${ratio}%`
  };

  const artworkStyles = {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0
  };

  return (
    <div className={className} style={containerStyles}>
      <svg
        ref={svgRef}
        viewBox={`0 0 ${width} ${height}`}
        dangerouslySetInnerHTML={{__html: processSVG(data, prefix)}}
        style={artworkStyles}
      />
    </div>
  );
}
