import { minMid } from './breakpoints';
var marginX = 50;
var marginNarrowX = 10;
var marginY = 50;
var marginNarrowY = 10;
var paddingY = 25;
var paddingNarrowY = 20;
export function getMarginX() {
  return minMid.matches ? marginX : marginNarrowX;
}
export function getMarginY() {
  return minMid.matches ? marginY : marginNarrowY;
}
export function getPaddingY() {
  return minMid.matches ? paddingY : paddingNarrowY;
}