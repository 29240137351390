import React from 'react';
import { InlineSVG, Placeholder } from '../../components';
import { TimelineMax, TweenMax } from 'gsap';
import '@global-av-survey/lib/vendor/DrawSVGPlugin';

import svgUrl from './sustainability.svg';

const speed = 4;

export default class Sustainability extends React.PureComponent {
  constructor () {
    super();
    this.state = {};
    this.animate = this.animate.bind(this);
  }

  componentWillReceiveProps (nextProps) {
    if (!this.state.isLoaded) return;

    if (nextProps.visibility === 'hidden') {
      this.timeline.pause();
    } else {
      this.timeline.resume();
    }
  }

  componentDidMount () {
    window.fetch(svgUrl)
      .then(res => res.text())
      .then(data => {
        this.svgData = data;
        this.setState({ isLoaded: true });
      })
      .catch(window.log.error);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isLoaded !== this.state.isLoaded) this.animate();
  }

  componentWillUnmount () {
    this.timeline.kill();
  }

  animate () {
    const EYE_UP = document.getElementById('sustainability-eye-up');
    const EYE_DOWN = document.getElementById('sustainability-eye-down');
    const EYE_RIGHT = document.getElementById('sustainability-eye-right');
    const EYE_LEFT = document.getElementById('sustainability-eye-left');
    const BOX_UP = document.getElementById('sustainability-box-up');
    const BOX_RIGHT = document.getElementById('sustainability-box-right');
    const BOX_DOWN = document.getElementById('sustainability-box-down');
    const CAR_UP = document.getElementById('sustainability-car-up');
    const CAR_RIGHT = document.getElementById('sustainability-car-right');
    const CAR_LEFT = document.getElementById('sustainability-car-left');

    this.timeline = new TimelineMax({ repeat: -1 });

    this.timeline.add([
      TweenMax.fromTo(EYE_UP,    speed/2,   {drawSVG: '0%' },       {drawSVG: '0% 100%'}),
      TweenMax.fromTo(EYE_RIGHT, speed,     {drawSVG: '0 0'},       {drawSVG: '0 100%'}),
      TweenMax.fromTo(EYE_DOWN,  speed,     {drawSVG: '100% 100%' },{drawSVG: '100% 0'}),
      TweenMax.fromTo(EYE_LEFT,  speed/2,   {drawSVG: '100% 100%' },{drawSVG: '0 100%'}),
      TweenMax.fromTo(CAR_UP,    speed/2,   {drawSVG: '0% 0%' },    {drawSVG: '0% 100%'}),
      TweenMax.fromTo(CAR_RIGHT, speed/2,   {drawSVG: '0%' },       {drawSVG: '0% 100%'}),
      TweenMax.fromTo(CAR_LEFT,  speed,     {drawSVG: '100% 100%' },{drawSVG: '0 100%'}),
      TweenMax.fromTo(BOX_UP,    speed/2,   {drawSVG: '0%' },       {drawSVG: '0% 100%'}),
      TweenMax.fromTo(BOX_RIGHT, speed/2,   {drawSVG: '0 0' },      {drawSVG: '100% 0%'}),
      TweenMax.fromTo(BOX_DOWN, speed/2,    {drawSVG: '100% 100%'}, {drawSVG: '100% 0'}),
    ]);

    this.timeline.add([
    TweenMax.fromTo(EYE_UP,    speed/2,   {drawSVG: '0% 100%' },       {drawSVG: '100% 100%'}),
    TweenMax.fromTo(EYE_RIGHT, speed,     {drawSVG: '0 100%'},       {drawSVG: '100% 100%'}),
    TweenMax.fromTo(EYE_DOWN,  speed,     {drawSVG: '100% 0' },{drawSVG: '0 0'}),
    TweenMax.fromTo(EYE_LEFT,  speed/2,   {drawSVG: '0% 100%' },{drawSVG: '0% 0%'}),
    TweenMax.fromTo(CAR_UP,    speed/2,   {drawSVG: '0% 100%' },    {drawSVG: '100% 100%'}),
    TweenMax.fromTo(CAR_RIGHT, speed/2,   {drawSVG: '0% 100%' },       {drawSVG: '100% 100%'}),
    TweenMax.fromTo(CAR_LEFT,  speed,     {drawSVG: '0% 100%' },{drawSVG: '0 0'}),
    TweenMax.fromTo(BOX_UP,    speed/2,   {drawSVG: '0% 100%' },       {drawSVG: '100% 100%'}),
    TweenMax.fromTo(BOX_RIGHT, speed/2,   {drawSVG: '100% 0' },      {drawSVG: '100% 100%'}),
    TweenMax.fromTo(BOX_DOWN, speed/2,    {drawSVG: '100% 0%'}, {drawSVG: '0 0'}),
    ]);

    this.timeline.play();
  }

  render () {
    if (!this.state.isLoaded) {
      return (
        <Placeholder
          width={1350}
          height={752}
        />
      );
    }

    return (
      <InlineSVG
        className={this.props.className}
        prefix="sustainability"
        data={this.svgData}
        width={1350}
        height={752}
        alt="Walking across a street"
      />
    );
  }
}
