import { basename, extname } from 'path';

import browser from 'bowser';
import { sum } from 'lodash';
import ease from 'ease-component';
import scroll from 'scroll';
import { bem, getProps } from '@global-av-survey/lib/helpers';
import { minMid, maxMid, minStandard, STANDARD, print } from '@global-av-survey/lib/styles/breakpoints';
import { getMarginX } from '@global-av-survey/lib/styles/sizes';
import Hammer from 'react-hammerjs';

import React from 'react';
import { Artwork } from '.';

function getImages (children) {
  const listEl = children.find(el => el.type === 'ul');
  if (!listEl) return null;

  return listEl.props.children
    .filter(React.isValidElement)
    .map(el => el.props.children[0].props);
}

function SlideshowItem ({
  active,
  alt,
  children,
  imageWidth,
  itemRef,
  index,
  introWidth,
  isIntro,
  isStart,
  lastIndex,
  marginX,
  scrollTo,
  src
}) {
  const isActive = active === index;
  const isPrevious = index < active;
  const isNext = index > active;

  const hasCursorImage = !browser.msie && !browser.msedge;

  let introStyles = {};

  if (!print.matches) {
    introStyles = {
      width: introWidth,
      marginLeft: marginX,
      marginRight: marginX
    };
  };

  if (isIntro) {
    return (
      <div className="Slideshow__item Slideshow__item-intro" style={introStyles}>
        <h3 className="Slideshow__header">Slideshow:</h3>
        {children.filter(el => el.type !== 'ul')}
        {maxMid.matches && <p className="Slideshow__hint">Swipe to advance the slideshow →</p>}
      </div>
    );
  }

  const styles = {
    width: imageWidth,
    marginRight: marginX
  };

  const classString = bem({
    name: 'Slideshow__item',
    props: { isActive }
  });

  const imgClassString = bem({
    name: 'Slideshow__item-image',
    props: { isActive, isPrevious, isNext }
  });

  const paginationProps = {
    hasCursorImage,
    index,
    isStart,
    lastIndex,
    marginX,
    scrollTo
  };

  return (
    <figure className={classString} style={styles} ref={itemRef}>
      <div className={imgClassString} onClick={event => { event.stopPropagation(); scrollTo(index) }}>
        <img alt={alt} src={src} />
        {isActive && minMid.matches && <Pagination {...paginationProps} />}
      </div>
      <figcaption className="Slideshow__item-caption">{alt}</figcaption>
    </figure>
  );
}

function handlePaginationClick({ index, targetIndex, scrollTo }, event) {
  event.stopPropagation();
  if (index === 0 && targetIndex <= 1) return;
  scrollTo(targetIndex);
}

function Pagination ({ isStart, hasCursorImage, index, lastIndex, marginX, scrollTo }) {
  const isEnd = index === lastIndex;

  const classString = bem({
    name: 'Slideshow__pagination',
    props: { isStart, isEnd }
  });

  return (
    <div className={classString}>
      {!isStart && (
        <div className="Slideshow__pagination-back" onClick={handlePaginationClick.bind(null, { scrollTo, index, targetIndex: index - 1 })}>
          {!hasCursorImage && <Artwork name="pagination-back" />}
        </div>
      )}
      {!isEnd && (
        <div className="Slideshow__pagination-next" onClick={handlePaginationClick.bind(null, { scrollTo, index, targetIndex: index + 1 })}>
          {!hasCursorImage && <Artwork name="pagination-next" />}
        </div>
      )}
    </div>
  );
}

function PaginationDot ({ active, imageNames, index, scrollTo }) {
  const isActive = minMid.matches
    ? (active - 1) === index
    : active === index;

  const targetIndex = minMid.matches
    ? index + 1
    : index;

  return (
    <button
      key={imageNames[index] + '-jump'}
      className={bem({ name: 'Slideshow__nav-item', props: { isActive }})}
      onClick={() => scrollTo(targetIndex)}
      type="button"
    >
      Jump
    </button>
  );
}

export default class Slideshow extends React.PureComponent {
  constructor (props) {
    super(props);

    this.handleSwipe = this.handleSwipe.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.setDimensions = this.setDimensions.bind(this);

    const images = getImages(this.props.children);

    this.els = [];

    this.state = {
      active: 0,
      images: [
        { isIntro: true },
        ...images
      ],
      imageNames: [
        'intro',
        ...images.map(img => basename(img.src, extname(img.src)))
      ]
    };
  }

  componentDidMount () {
    this.setDimensions();
    window.addEventListener('resize', this.setDimensions);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.setDimensions);
  }

  componentWillUpdate (nextProps, nextState) {
    if (this.state.isNarrow === nextState.isNarrow) return;

    nextState.isNarrow
      ? this.setState({ active: 0 })
      : this.setState({ active: 1 });
  }

  setDimensions () {
    const marginX = getMarginX();

    const viewportWidth = minStandard.matches
      ? STANDARD
      : window.innerWidth;

    let imageWidth;
    let introWidth;

    if (minMid.matches) {
      imageWidth = viewportWidth * 0.55;
      introWidth = (viewportWidth - imageWidth - (marginX * 4)) / 2;
    } else {
      imageWidth = viewportWidth * 0.80;
      introWidth = imageWidth;
    }

    const restartWidth = minMid.matches
      ? 130
      : 60 + marginX; // hardcoded because refs are unavailable until width is set

    const totalWidth = sum([].concat(
      marginX,
      introWidth,
      marginX,
      this.state.images.map(i => imageWidth + marginX),
      restartWidth,
      marginX,
    ));

    this.setState({
      imageWidth,
      introWidth,
      marginX,
      width: totalWidth,
      isNarrow: maxMid.matches,
    });

    this.scrollTo(this.state.active);
  }

  handleSwipe (event) {
    if (event.direction === 4) this.scrollTo(this.state.active - 1);
    if (event.direction === 2) this.scrollTo(this.state.active + 1);
  }

  scrollTo (index) {
    if (!this.viewerEl || this.state.isAnimating === true) return;
    if (index < 0) return;
    if (index >= this.state.images.length) return;

    const { introWidth, marginX } = this.state;
    const targetEl = this.els[index];

    let targetX;
    if (index === 0) targetX = 0;

    if (targetEl) {
      targetX = minMid.matches
        ? targetEl.offsetLeft - (introWidth + marginX * 2)
        : targetEl.offsetLeft - marginX;
    }

    if (targetX === undefined) return;

    this.setState({ isAnimating: true });

    if (document.scrollingElement) {
      scroll.left(this.viewerEl, targetX, { ease: ease.inOutExpo, duration: 1000 }, () => {
        this.setState({
          isAnimating: false,
          active: index
        });
      });
    }
  }

  render () {
    const { children, className = '' } = this.props;
    const { active, images, imageNames, imageWidth, introWidth, isAnimating, marginX, width } = this.state;

    if (!width) return null;

    const wrapperStyles = {
      width,
      paddingRight: introWidth + marginX
    };

    const startIndex = minMid.matches
      ? 1
      : 0;

    const isStart = startIndex === active;

    const itemProps = {
      active,
      children,
      imageWidth,
      introWidth,
      marginX,
      scrollTo: this.scrollTo,
    };

    const paginationProps = {
      active,
      imageNames,
      scrollTo: this.scrollTo,
    };

    const paginationOptions = minMid.matches
      ? images.slice(1)
      : images;

    const htmlProps = getProps({ name: 'Slideshow', props: { className, isAnimating } });

    const hammerOptions = {
      recognizers: {
        swipe: {
          threshold: 1,
          velocity: .3
        }
      }
    };

    return (
      <div {...htmlProps} ref={el => this.el = el}>
        <div className="Slideshow__nav">
          <div>
            {paginationOptions.map((img, index) => (
              <PaginationDot key={imageNames[index]} index={index} {...paginationProps} />
            ))}
          </div>
        </div>
        <div className="Slideshow__viewer" ref={el => this.viewerEl = el}>
          <Hammer onSwipe={this.handleSwipe} direction="DIRECTION_HORIZONTAL" options={hammerOptions}>
            <div className="Slideshow__content" style={wrapperStyles}>
              <div className="Slideshow__images">
                {images.map((imageProps, index) => (
                  <SlideshowItem
                    {...itemProps}
                    {...imageProps}
                    key={imageNames[index]}
                    isStart={isStart}
                    itemRef={el => this.els[index] = el}
                    index={index}
                    lastIndex={images.length - 1}
                  />
                ))}
                <div className="Slideshow__item" ref={el => this.restartEl = el}>
                  <button className="Slideshow__restart" onClick={() => this.scrollTo(startIndex)}>
                    <img
                      alt="Transparent"
                      src={images[1].src}
                      width={imageWidth}
                    />
                    <div className="Slideshow__restart-trigger">
                      <Artwork name="restart" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </Hammer>
        </div>
      </div>
    );
  }
}
