import React from 'react';
import { InlineSVG, Placeholder } from '../../components';
import { scaleCoordinate } from '@global-av-survey/lib/helpers';

import svgUrl from './urban-transformation.svg';

function google (els, event) {
  const eyeEl = els[0];
  const eye = eyeEl.getBBox();
  const pupilEl = els[1];
  const pupil = pupilEl.getBBox();

  const oldMin = [
    0,
    0
  ];

  const oldMax = [
    window.innerWidth,
    window.innerHeight
  ];

  const newMin = [
    eye.x,
    eye.y
  ];

  const newMax = [
    eye.x + eye.width - (pupil.width / 2),
    eye.y + eye.height - (pupil.height / 2)
  ];

  const target = scaleCoordinate({ oldMin, oldMax, newMin, newMax, x: event.clientX, y: event.clientY });

  pupilEl.setAttribute('cx', target.x);
  pupilEl.setAttribute('cy', target.y);
}

export default class UrbanTransformation extends React.PureComponent {
  constructor () {
    super();
    this.state = {};
    this.googleAll = this.googleAll.bind(this);
  }

  componentDidMount () {
    window.fetch(svgUrl)
      .then(res => res.text())
      .then(data => {
        this.svgData = data;
        this.setState({ isLoaded: true });
      })
      .catch(window.log.error);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isLoaded !== this.state.isLoaded) {
      this.eyes = [
        [
          document.getElementById('urban-left-bus-eye'),
          document.getElementById('urban-left-bus-pupil')
        ],
        [
          document.getElementById('urban-right-bus-eye'),
          document.getElementById('urban-right-bus-pupil')
        ],
        [
          document.getElementById('urban-left-barrier-eye'),
          document.getElementById('urban-left-barrier-pupil')
        ],
        [
          document.getElementById('urban-right-barrier-eye'),
          document.getElementById('urban-right-barrier-pupil')
        ],
        [
          document.getElementById('urban-left-bike-eye'),
          document.getElementById('urban-left-bike-pupil')

        ],
        [
          document.getElementById('urban-right-bike-eye'),
          document.getElementById('urban-right-bike-pupil')
        ]
      ];

      window.addEventListener('mousemove', this.googleAll);
    }
  }

  componentWillUnmount () {
    window.removeEventListener('mousemove', this.googleAll);
  }

  googleAll (event) {
    this.eyes.forEach(eye => google(eye, event));
  }

  render () {
    if (!this.state.isLoaded) {
      return (
        <Placeholder
          width={1350}
          height={752}
        />
      );
    }

    return (
      <InlineSVG
        className={this.props.className}
        prefix="urban"
        data={this.svgData}
        width={1350}
        height={752}
        alt="Bus and barrier with eyes"
      />
    );
  }
}
