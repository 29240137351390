import React from 'react';
import { InlineSVG, Placeholder } from '../../components';
import { TimelineMax, TweenMax } from 'gsap';

import svgUrl from './mobility.svg';

const delay = 1;

export default class Mobility extends React.PureComponent {
  constructor () {
    super();
    this.state = {};
    this.animate = this.animate.bind(this);
  }

  componentWillReceiveProps (nextProps) {
    if (!this.state.isLoaded || !this.timeline) return;

    (nextProps.visibility === 'hidden')
      ? this.timeline.pause()
      : this.timeline.resume();
  }

  componentDidMount () {
    window.fetch(svgUrl)
      .then(res => res.text())
      .then(data => {
        this.svgData = data;
        this.setState({ isLoaded: true });
      })
      .catch(console.error);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isLoaded !== this.state.isLoaded) this.animate();
  }

  componentWillUnmount () {
    this.timeline.kill();
  }

  animate () {
    const HANDICAP = document.getElementById('mobility-handicap');
    const GROCERY = document.getElementById('mobility-grocery');
    const KIDS = document.getElementById('mobility-kids');
    const DOGS = document.getElementById('mobility-dogs');

    this.timeline = new TimelineMax({ repeat: -1 });

    TweenMax.set([
      HANDICAP,
      GROCERY,
      KIDS,
      DOGS
    ], { opacity: 0 });

    const SHOW = { opacity: 1 };
    const HIDE = { delay, opacity: 0 };

    this.timeline.add([
      TweenMax.to(HANDICAP, 0, SHOW),
      TweenMax.to(HANDICAP, 0, HIDE),
      TweenMax.to(GROCERY,  0, SHOW),
      TweenMax.to(GROCERY,  0, HIDE),
      TweenMax.to(KIDS,     0, SHOW),
      TweenMax.to(KIDS,     0, HIDE),
      TweenMax.to(DOGS,     0, SHOW),
      TweenMax.to(DOGS,     0, HIDE),
    ], '+=0', 'sequence', 0);

    this.timeline.play(4);
  }

  render () {
    if (!this.state.isLoaded) {
      return (
        <Placeholder
          width={1350}
          height={752}
        />
      );
    }

    return (
      <InlineSVG
        className={this.props.className}
        prefix="mobility"
        data={this.svgData}
        width={1350}
        height={752}
        alt="Examples of different groups using a taxi"
      />
    );
  }
}
