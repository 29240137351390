var AQUA = 'hsl(147, 59%,  67%)';
var BLACK = 'hsl(0,   0%,   0%)';
var BLUE = 'rgb(150, 215,  240)';
var GRAY = 'hsl(0,   0%,   65%)';
var GREEN = 'hsl(147, 59%,  67%)';
var LIME = 'hsl(68,  100%, 45%)';
var OFFWHITE = 'hsl(0,   0%,   96%)';
var ORANGE = 'hsl(36,  82%,  56%)';
var PURPLE = 'hsl(223, 100%, 79%)';
var SILVER = 'hsl(210, 4%,   91%)';
var YELLOW = 'hsl(44,  100%, 60%)';
var RED = '#ff8264';
var WHITE = 'hsl(0,  100%, 100%)';
var ACCORD = PURPLE;
var ATLAS = AQUA;
var BLUEPRINTS = BLUE;
var PRIMER = GRAY;
var SCENARIOS = ORANGE;
var SHAREDSTREETS = RED;
module.exports = {
  ACCORD: ACCORD,
  ATLAS: ATLAS,
  AQUA: AQUA,
  BLACK: BLACK,
  BLUE: BLUE,
  BLUEPRINTS: BLUEPRINTS,
  GRAY: GRAY,
  GREEN: GREEN,
  LIME: LIME,
  OFFWHITE: OFFWHITE,
  ORANGE: ORANGE,
  PRIMER: PRIMER,
  PURPLE: PURPLE,
  RED: RED,
  SCENARIOS: SCENARIOS,
  SHAREDSTREETS: SHAREDSTREETS,
  SILVER: SILVER,
  YELLOW: YELLOW,
  WHITE: WHITE
};