//import { unorphan } from '@global-av-survey/lib/helpers';
import React from 'react';
import { Layout } from '.';
import { OffsiteLink } from '../components';

// using the About page CSS
export default class AboutScenarios extends React.PureComponent {
  componentDidMount () {
    //unorphan();
  }

  componentDidUpdate () {
    //unorphan();
  }

  render () {

    return (
      <Layout>
        <main className="Layout__main">
          <header className="AboutScenarios__header">
            <h2>
            About this Site
            </h2>
          </header>
          <section className="AboutScenarios__details">
            <p>
            The scenarios on this website were developed by Bits and Atoms and Dash Marshall with support from the Bloomberg Aspen Initiative on Cities and Autonomous Vehicles.
            </p>

            <p>
            Foresight and Scenario Development: <OffsiteLink to="http://bitsandatoms.net">Bits and Atoms</OffsiteLink> and <OffsiteLink to="http://dashmarshall.com">Dash Marshall</OffsiteLink>
            </p>
            <p>
            Production Coordination: Melissa De la Cruz
            </p>
            <p>
            Graphic design and Illustration: <OffsiteLink to="http://mightcould.com">Ryan Thacker</OffsiteLink>
            </p>
            <p>
            Code: <OffsiteLink to="http://pushred.co/">Push the Red Button</OffsiteLink>
            </p>
            <p>
            Scenario reporting: <OffsiteLink to="http://www.greglindsay.org/">Greg Lindsay</OffsiteLink>
            </p>
            <p>
            Scenario Illustrations: <OffsiteLink to="http://kristenboydstun.com/">Kristin Boydstun</OffsiteLink>, <OffsiteLink to="http://nickiluzada.com/">Nick Iluzada</OffsiteLink>, and <OffsiteLink to="http://www.studiomuti.co.za/">Studio Muti</OffsiteLink>
            </p>
            <p>
            Scenario Advisory Group:
            Shaun Abrahamson (<OffsiteLink to="http://urban.us/">Urban.Us</OffsiteLink>),
            Varun Adhibatla (<OffsiteLink to="http://www.argolabs.org/">Argo</OffsiteLink>),
            Francesca Birks (<OffsiteLink to="https://www.arup.com/">Arup</OffsiteLink>),
            Garry Golden (<OffsiteLink to="http://futurethink.com/">FutureThink</OffsiteLink>),
            Eric Goldwyn (<OffsiteLink to="http://marroninstitute.nyu.edu/">NYU Marron Institute</OffsiteLink>),
            Greg Lindsay (<OffsiteLink to="http://www.greglindsay.org/">Senior Fellow, New Cities</OffsiteLink>),
            Chelsea Mauldin (<OffsiteLink to="http://publicpolicylab.org/">Public Policy Lab</OffsiteLink>),
            Ritchie Yao (<OffsiteLink to="http://dashmarshall.com">Dash Marshall</OffsiteLink>)
            </p>
          </section>
        </main>
      </Layout>
    );
  }
}
